<template>
  <c-box
    width="100%"
    max-width="1270px"
    background-color="#FFF"
    margin-bottom="16px"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :padding="['16px', '30px']"
    :min-height="['unset', '74vh']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        padding="20px 90px"
        :paths="[
          { label: 'Beranda', href: { name: 'nutrisionist.managementClient' } },
          { label: 'Manajemen Klien', href: { name: 'nutrisionist.managementClient' } },
          { label: 'Detail Klien', href: { name: 'nutri.detail-client', query: $route.query } },
          { label: 'Detail Program', isCurrent: true },
        ]"
      />
    </Portal>
    <c-box
      display="flex"
      flex-direction="column"
      gap="30px"
      padding="0 40px"
    >
      <c-box
        display="flex"
        justify-content="space-between"
        align-items="center"
      >
        <BaseText
          size-mobile="20px"
          size-desktop="28px"
        >
          Detail Program
        </BaseText>
        <BaseText
          size-mobile="18px"
          size-desktop="20px"
          color="#008C81"
        >
          {{ program.name }}
        </BaseText>
      </c-box>
      <c-box>
        <BaseText
          size-mobile="18px"
          size-desktop="20px"
          color="#008C81"
        >
          Data Pribadi
        </BaseText>
        <BaseDivider margin="10px 0 30px 0" />
        <c-box
          display="flex"
          gap="30px"
        >
          <c-box>
            <c-image
              border-radius="100% "
              object-fit="cover"
              size="150px"
              :src="getPhotoUser(user.photoUrl)"
              :alt="user.fullName"
            />
          </c-box>
          <c-box
            width="100%"
            flex="1"
          >
            <BaseText
              size-mobile="14px-2"
              size-desktop="16px"
            >
              Nama
            </BaseText>
            <BaseText
              size-mobile="16px"
              size-desktop="18px"
            >
              {{ user.fullName }}
            </BaseText>
            <BaseText
              margin-top="24px"
              size-mobile="14px-2"
              size-desktop="16px"
            >
              Program
            </BaseText>
            <BaseText
              size-mobile="16px"
              size-desktop="18px"
            >
              {{ program.name }} ({{ program.programService }})
            </BaseText>
            <BaseText
              margin-top="24px"
              size-mobile="14px-2"
              size-desktop="16px"
            >
              Join Date - End Date
            </BaseText>
            <BaseText
              size-mobile="16px"
              size-desktop="18px"
            >
              {{ formatDateV2(program.startAt) }} - {{ formatDateV2(program.endAt) }}
            </BaseText>
            <BaseText
              margin-top="24px"
              size-mobile="14px-2"
              size-desktop="16px"
            >
              Durasi
            </BaseText>
            <BaseText
              size-mobile="16px"
              size-desktop="18px"
            >
              {{ program.duration }} Hari
            </BaseText>
          </c-box>
          <c-box>
            <BaseText
              size-mobile="14px-2"
              size-desktop="16px"
              color="#008C81"
            >
              Counter day: {{ program.counterDay }}
            </BaseText>
          </c-box>
        </c-box>
      </c-box>
      <c-box>
        <BaseText
          size-mobile="18px"
          size-desktop="20px"
          color="#008C81"
        >
          Ahli Gizi yang Dipilih
        </BaseText>
        <BaseDivider margin="10px 0 30px 0" />
        <CardNutritionist
          :name="getFullnameAndTitle(nutritionist.fullName, nutritionist.education)"
          :photo="nutritionist.photoUrl"
          :service-hours="nutritionist.serviceHour"
          :ratings="nutritionist.rating"
          :educations="educationsParser(nutritionist.education)"
          :specializations="nutritionist.specialization"
          @on-show-profile="isModalOpen = true"
        />
      </c-box>

      <c-box>
        <BaseText
          size-mobile="18px"
          size-desktop="20px"
          color="#008C81"
        >
          Diet Data
        </BaseText>
        <BaseDivider margin="10px 0 30px 0" />
        <c-box
          display="grid"
          grid-template-columns="repeat(2, minmax(0, 1fr))"
          grid-gap="30px"
        >
          <SimpleCard
            v-for="menu in LIST_MENU_DIET_DATA"
            :key="menu.label"
            :icon="menu.icon"
            :label="menu.label"
            :button-label="menu.buttonLabel"
            @on-button-click="menu.onButtonClick && routerPush(menu.onButtonClick)"
            @on-click="routerPush(menu.onClick)"
          />
        </c-box>
      </c-box>
      
      <c-box>
        <BaseText
          size-mobile="18px"
          size-desktop="20px"
          color="#008C81"
        >
          Jadwal Rencana Makan
        </BaseText>
        <BaseDivider margin="10px 0 30px 0" />
        <c-box
          display="grid"
          grid-template-columns="repeat(2, minmax(0, 1fr))"
          grid-gap="30px"
        >
          <SimpleCard2
            v-for="v in meal_plan_schedule"
            :key="v.id"
            :title="v.title"
            :description="v.date"
            :status="v.status"
          />
        </c-box>
      </c-box>
      <c-box>
        <BaseText
          size-mobile="18px"
          size-desktop="20px"
          color="#008C81"
        >
          Jadwal ADIME Notes & Indikator Progress and Tracker
        </BaseText>
        <BaseDivider margin="10px 0 30px 0" />
        <c-box
          display="grid"
          grid-template-columns="repeat(2, minmax(0, 1fr))"
          grid-gap="30px"
        >
          <SimpleCard2
            v-for="v in adime_notes_and_indicator_progress_and_tracker_schedule"
            :key="v.id"
            :title="v.title"
            :description="v.date"
            :status="v.status"
          />
        </c-box>
      </c-box>
      <c-box>
        <BaseText
          size-mobile="18px"
          size-desktop="20px"
          color="#008C81"
        >
          Jadwal Follow Up Notes
        </BaseText>
        <BaseDivider margin="10px 0 30px 0" />
        <c-box
          display="grid"
          grid-template-columns="repeat(2, minmax(0, 1fr))"
          grid-gap="30px"
        >
          <SimpleCard2
            v-for="v in follow_up_notes_schedule"
            :key="v.id"
            :title="v.title"
            :description="v.date"
            :status="v.status"
          />
        </c-box>
      </c-box>
      <c-box>
        <BaseText
          size-mobile="18px"
          size-desktop="20px"
          color="#008C81"
        >
          Jadwal Progress & Tracker Feedback
        </BaseText>
        <BaseDivider margin="10px 0 30px 0" />
        <c-box
          display="grid"
          grid-template-columns="repeat(2, minmax(0, 1fr))"
          grid-gap="30px"
        >
          <SimpleCard2
            v-for="v in progress_and_tracker_feedback_schedule"
            :key="v.id"
            :title="v.title"
            :description="v.date"
            :status="v.status"
          />
        </c-box>
      </c-box>
    </c-box>

    <ModalNutritionists
      :is-show-skeleton="isShowSkeletonModalNutritionist"
      :is-open="isModalOpen"
      :fullname="getFullnameAndTitle(`${nutritionistModal.firstName || ''} ${nutritionistModal.lastName || ''}`.trim(), nutritionistModal.education)"
      :photo-url="nutritionistModal.photoUrl"
      :str-number="nutritionistModal.str"
      :languages="nutritionistModal.languages ? [
        nutritionistModal.languages
      ] : []"
      :rating="nutritionistModal.rating"
      :problem-handles="nutritionistModal.problemHandled"
      :client-age-handles="nutritionistModal.clientAgeHandled ? [
        nutritionistModal.clientAgeHandled,
      ] : []"
      :specializations="nutritionistModal.specialization"
      :educations="educationsParser(nutritionistModal.education)"
      :work-experiences="workExperiencesParser(nutritionistModal.workExperiences)"
      :service-hour="nutritionistModal.serviceHour"
      :chat-active-hours="chatActiveHoursParser(nutritionistModal.activeHour)"
      :service-times="serviceTimesParser(nutritionistModal.serviceTime)"
      @on-close="isModalOpen = false"
    />
  </c-box>
</template>

<script>
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import ModalNutritionists from '@/components/widgets/modal-nutritionists.vue'
import CardNutritionist from '@/components/widgets/card-nutritionist.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseDivider from '@/components/elements/base-divider.vue'
import SimpleCard from '@/components/widgets/simple-card.vue'
import SimpleCard2 from '@/components/widgets/simple-card-2.vue'
import { mapActions } from 'vuex'
import generalMixin from '@/utils/general-mixins'
import { formatDateV2 } from '@/utils/format-date'
import modalNutritionistsMixin from '@/components/widgets/modal-nutritionists-mixin'

export default {
  name: 'ManagementClientPrograms',
  components: {
    SimpleCard2,
    BaseDivider,
    BaseText,
    CardNutritionist,
    ModalNutritionists,
    BreadcrumbPath,
    SimpleCard,
  },
  mixins: [
    generalMixin,
    modalNutritionistsMixin,
  ],
  data() {
    return {
      meal_plan_schedule: [],
      adime_notes_and_indicator_progress_and_tracker_schedule: [],
      follow_up_notes_schedule: [],
      progress_and_tracker_feedback_schedule: [],
      program: {},
      user: {},
      nutritionist: {},
      universities: {},
      isModalOpen: false,
      isShowSkeletonModalNutritionist: true,
      nutritionistModal: {},
    }
  },
  computed: {
    LIST_MENU_DIET_DATA() {
      return [
        {
          label: 'Rencana Makan',
          buttonLabel: 'Kirim',
          icon: 'https://ik.imagekit.io/dietela/pwa_webp/nutrisionist/mealplan_icon.webp?updatedAt=1688397449063',
          onClick: null, // TODO: implement me!
          onButtonClick: null, // TODO: implement me!
          // onButtonClick: 'rencana-makan-send',
        },
        {
          label: 'Catatan Evaluasi',
          icon: 'https://ik.imagekit.io/dietela/pwa_webp/nutrisionist/note_icon.webp?updatedAt=1688397449187',
          onClick: null, // TODO: implement me!
          onButtonClick: null,
        },
        {
          label: 'Pemantauan',
          icon: 'https://ik.imagekit.io/dietela/pwa_webp/nutrisionist/tracker_icon.webp?updatedAt=1688397449189',
          onClick: null, // TODO: implement me!
          onButtonClick: null,
        },
        {
          label: 'Formulir Gizi',
          icon: 'https://ik.imagekit.io/dietela/pwa_webp/nutrisionist/questionnaire_icon.webp?updatedAt=1688397449260',
          onClick: {
            name: 'nutri.detail-quizionary',
            params: {
              programId: this.program?.id || '',
            },
          }, // TODO: implement me!
          onButtonClick: null,
        },
      ]
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    formatDateV2,
    ...mapActions({
      loadUniversities: 'general/loadUniversities',
      getNutritionistDetailProgram: 'clients/getNutritionistDetailProgram',
      getNutritionistDetailProgramNutritionist: 'clients/getNutritionistDetailProgramNutritionist',
      getNutritionistDetailProgramMealPlan: 'clients/getNutritionistDetailProgramMealPlan',
      getNutritionistDetailProgramAdime: 'clients/getNutritionistDetailProgramAdime',
      getNutritionistDetailProgramFollowUp: 'clients/getNutritionistDetailProgramFollowUp',
      getNutritionistDetailProgramProgressTracker: 'clients/getNutritionistDetailProgramProgressTracker',
    }),
    routerPush(path) {
      this.$router.push(path)
    },
    async init() {
      const programId = this.$route.params.programId
      try {
        // const res = await this.getNutritionistDetailProgram('69383be5-6061-46ba-95d9-b2b32cd399ac')
        const [
          resLoadUniversities,
          resGetNutritionistDetailProgram,
          resGetNutritionistDetailProgramMealPlan,
          resGetNutritionistDetailProgramAdime,
          resGetNutritionistDetailProgramFollowUp,
          resGetNutritionistDetailProgramProgressTracker,
        ] = await Promise.all([
          this.loadUniversities(),
          this.getNutritionistDetailProgram(programId),
          this.getNutritionistDetailProgramMealPlan(programId),
          this.getNutritionistDetailProgramAdime(programId),
          this.getNutritionistDetailProgramFollowUp(programId),
          this.getNutritionistDetailProgramProgressTracker(programId),
        ])

        this.program = resGetNutritionistDetailProgram.data.program
        this.user = resGetNutritionistDetailProgram.data.user
        this.nutritionist = resGetNutritionistDetailProgram.data.nutritionist
        this.universities = resLoadUniversities
        this.meal_plan_schedule = resGetNutritionistDetailProgramMealPlan.data
        this.adime_notes_and_indicator_progress_and_tracker_schedule = resGetNutritionistDetailProgramAdime.data
        this.follow_up_notes_schedule = resGetNutritionistDetailProgramFollowUp.data
        this.progress_and_tracker_feedback_schedule = resGetNutritionistDetailProgramProgressTracker.data

        if (resGetNutritionistDetailProgram.data?.nutritionist?.id) {
          const nutritionistModal = await this.getNutritionistDetailProgramNutritionist(resGetNutritionistDetailProgram.data?.nutritionist?.id).then(v => v.data)
          this.nutritionistModal = nutritionistModal
          this.isShowSkeletonModalNutritionist = false
        }

      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>
